import momemt from 'moment';

export default function Events() {
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/Young%20Nudy/events?app_id=45PRESS',
		method: 'GET',
		dataType: 'json',
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			console.log( data );
			if ( data.length ) {
				for ( let event of data ) {
					const location = event.venue.city + ', ' + event.venue.region;
					html += '<div>';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'MM/DD' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn btn-outline">Buy Tickets</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				events.html( html );

				if ( $( '#events>div' ).length > 1 ) {
					$( '#tour .more' ).show();
				}
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );

	$( '#tour .more a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#events>div' ).css( 'display', 'flex' );
		$( this ).hide();
	} );

}