require( 'owl.carousel' );

export default function Carousels() {
	$( '#music .owl-carousel' ).owlCarousel( {
		items: 4,
		loop: true,
		nav: true,
		dots: false,
		navText: [
			'<img src="./dist/img/Left_Arrow.png" alt="Prev" class="arrow">',
			'<img src="./dist/img/Right_Arrow.png" alt="Next" class="arrow">',
		],
		margin: 35,
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			768: {
				items: 4,
				margin: 20
			}
		}
	} );


	$( '#videos .owl-carousel' ).owlCarousel( {
		items: 2,
		loop: true,
		nav: true,
		dots: false,
		navText: [
			'<img src="./dist/img/Left_Arrow.png" alt="Prev" class="arrow">',
			'<img src="./dist/img/Right_Arrow.png" alt="Next" class="arrow">',
		],
		margin: 0,
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			768: {
				items: 2,
				margin: 0
			}
		}
	} );

	$( '#merch .owl-carousel' ).owlCarousel( {
		items: 3,
		loop: true,
		nav: true,
		dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 2000,
		autoplayHoverPause: true,
		navText:[
			'<img src="./dist/img/Left_Arrow.png" alt="Prev" class="arrow">',
			'<img src="./dist/img/Right_Arrow.png" alt="Next" class="arrow">',
		],
		margin: 20,
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 3,
			}
		}
	} );
}