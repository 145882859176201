import Events from './components/events';
import Carousels from './components/carousels';

$( document ).ready( function () {
	Events();
	Carousels();

	AOS.init(); 

	$(".newsletter").on("submit", function(e) {
		// Prevent default browser actions on form submission.
		e.preventDefault();
		// Get all the form user submitted data and get it ready to be sent.
		var data = $(this).serialize();
		// POST all the data to the SMF submission endpoint URL.
		$.ajax({
			type: "POST",
			url: "https://subs.sonymusicfans.com/submit",
			dataType: "json",
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function(data) {
				$('.newsletter').html('<p>Thank you for signing up</p>');
			},
			error: function(err) {
			}
		});
	});
 
	$('.scroll').on('click', function(e) {
		const TARGET = $(this).attr('href');
	
		e.preventDefault();
	
		$('body, html').animate({
			scrollTop: $(TARGET).offset().top
		});
	});
} );